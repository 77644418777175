import styled from 'styled-components';

export const StyledAddOperatorForm = styled.div`
  height: 100%;

  .ant-modal-header {
    .ant-modal-title {
      padding: 0;
    }
  }

  .ant-modal-content {
    width: 600px;
  }

  .operator-form {
    ${(props): string => props.theme.fontStyles.p1.news};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .operator-form__title {
    ${(props): string => props.theme.fontStyles.heading1};
  }

  .operator-form__content {
    .operator-form__select-phone-code {
      width: 27%;
      border-right: none;
    }
    .operator-form__info-user__input-phone-code {
      width: 73%;
    }
    .operator-form__description {
      ${(props): string => props.theme.fontStyles.p1.news};
      color: ${(props): string => props.theme.colors.black};
      margin-bottom: 24px;
    }

    .operator-form__note-options {
      ${(props): string => props.theme.fontStyles.p1.bold};
      color: ${(props): string => props.theme.colors.black};
      margin-top: 16px;
      text-transform: uppercase;
    }

    .operator-form__note {
      ${(props): string => props.theme.fontStyles.p1.news};
      color: ${(props): string => props.theme.colors.black};
      width: 536px;
      margin-top: 16px;
      margin-bottom: 12px;
    }
    .operator-form__recommended {
      ${(props): string => props.theme.fontStyles.captionSmall};
      color: ${(props): string => props.theme.colors.darkGrey};
      margin: 16px 0 16px;
    }

    .operator-form__info-user {
      .ant-form-item-label {
        ${(props): string => props.theme.fontStyles.caption.bold};
        margin-bottom: 0px;
      }

      .operator-form__info-user__input {
        ${(props): string => props.theme.fontStyles.caption.medium};
      }
    }
    .operator-form__checkbox-group {
      margin-bottom: 24px;
      row-gap: 0px !important;
      .ant-form-item {
        margin-bottom: 0px;
        padding-bottom: 4px;
      }
    }
    .operator-form__checkbox-label {
      ${(props): string => props.theme.fontStyles.p1.news};
      color: ${(props): string => props.theme.colors.black};
    }
    .operator-form__checkbox-label.has-error {
      .ant-checkbox-inner {
        border-color: ${(props): string => props.theme.colors.semanticError};
      }
    }
    .operator-form__checkbox {
      margin-top: 0px;
      .ant-form-item {
        margin-bottom: 0px;
        padding-bottom: 6px;
      }

      .ant-form-item-control-input {
        min-height: 32px;
      }
    }
  }
  .operator-form__actions {
    display: flex;
    justify-content: space-between;

    .submit-button,
    .cancel-button {
      font-family: ${(props): string => props.theme.fonts.redesign.lg.strong} !important;
      font-size: ${(props): string => props.theme.fontSizes.redesign.lg} !important;
      line-height: ${(props): string => props.theme.lineHeights.redesign.lg} !important;
      font-weight: ${(props): number => props.theme.fontWeights.redesign.lg} !important;
      padding: 8px 16px !important;
      height: 40px !important;
    }

    .cancel-button {
      border: 2px solid ${(props): string => props.theme.colors.gray100} !important;
    }

    .submit-button {
      border: none !important;
    }
  }

  .operator-form__info-user__input-group {
    width: 100%;
    margin-bottom: 16px;
    .ant-form-item-control-input {
      min-height: 32px;
    }
  }

  .ant-form-item-label {
    ${(props): string => props.theme.fontStyles.redesign.sm.delete}
    padding: 0;
  }

  .operator-form__select {
    display: flex;
    align-items: center;
    .ant-select-selector {
      width: 100%;
    }
    .ant-select-arrow {
      margin-top: -5px !important;
    }
  }

  .operator-form__info-user__selector {
    .ant-select-selection-placeholder {
      color: ${(props): string => props.theme.colors.grey300} !important;
    }

    .ant-select-selection-overflow-item {
      span {
        ${(props): string => props.theme.mixins.truncateText}
      }
    }
  }
`;
