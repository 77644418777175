import { Col, Empty, Flex, Form, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { StyledOperatorDetailsDrawer } from './OperatorDetailsDrawer.styles';
import { getOperatorSelectedDevicesColumns } from './columns/OperatorSelectedDevicesColumns.columns';
import { supportedLanguages } from 'app/cross-cutting-concerns/translations/Translations';
import { selectIsOperatorDetailsDrawerVisible } from 'app/cross-cutting-concerns/drawers/state/drawersSelectors';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import * as UserListSelectors from 'app/modules/user-management/user-list/state/UserListSelectors';
import { PopConfirm } from 'lib/components/PopConfirm/PopConfirm';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { Optional } from 'lib/types/Optional';
import { NotificationOperator } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { useAnalyticsSetForm } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { OperatorFormValues } from 'app/modules/user-management/interfaces/Operator.types';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { UserListActions } from 'app/modules/user-management/user-list/state/userListActions';
import { Input } from 'lib/components/Input/Input';
import { Select } from 'lib/components/Select/Select';
import { Table } from 'lib/components/Table/Table';
import { Checkbox } from 'lib/components/Checkbox/Checkbox';
import { selectHasAccessToRobots } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Card } from 'lib/components/Card/Card';
import { Permission } from 'config/permissions';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { UserModalsActions } from 'app/modules/user-management/modals/state/userModalsActions';
import { OpenSearch } from 'config/constants';
import { DeleteOperatorModal } from 'app/modules/user-management/modals/components/DeleteOperatorModal/DeleteOperatorModal';

const languageOptions = Object.entries(supportedLanguages).map(([key, values]) => ({
  value: key === 'en-US' ? 'en' : key,
  content: values.name,
}));

const countries = getCountries().map(country => ({
  code: country,
  dialCode: `+${getCountryCallingCode(country)}`,
  flag: country.toUpperCase().replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt(0))),
}));
export interface NotificationCategoryType {
  name: string;
  label: string;
  isRecommended: boolean;
  visible: boolean;
}

export const getNotificationCategories = ({
  showRobotCategories,
  isMachineReminderListEnabled,
}: {
  showRobotCategories: boolean;
  isMachineReminderListEnabled: boolean;
}): NotificationCategoryType[] => [
  {
    name: 'notifyLocationStatus',
    label: 'userList.operatorForm.form.locationStatus',
    isRecommended: false,
    visible: true,
  },
  {
    name: 'notifyResources',
    label: 'userList.operatorForm.form.resources',
    isRecommended: true,
    visible: showRobotCategories,
  },
  {
    name: 'notifyCleaningTaskInterrupted',
    label: 'userList.operatorForm.form.cleaningTaskInterrupted',
    isRecommended: true,
    visible: showRobotCategories,
  },
  {
    name: 'notifyCleaningTaskFinished',
    label: 'userList.operatorForm.form.cleaningTaskFinished',
    isRecommended: true,
    visible: showRobotCategories,
  },
  {
    name: 'notifyEmergencyStop',
    label: 'userList.operatorForm.form.emergencyStop',
    isRecommended: true,
    visible: showRobotCategories,
  },
  {
    name: 'notifyErrorOccurred',
    label: 'userList.operatorForm.form.errorOccurred',
    isRecommended: true,
    visible: showRobotCategories,
  },
  {
    name: 'notifyInformation',
    label: 'userList.operatorForm.form.information',
    isRecommended: true,
    visible: showRobotCategories,
  },
  {
    name: 'notifyServiceNeeded',
    label: 'userList.operatorForm.form.serviceNeeded',
    isRecommended: true,
    visible: showRobotCategories,
  },
  {
    name: 'notifyReminder',
    label: 'userList.operatorForm.form.reminder',
    isRecommended: false,
    visible: isMachineReminderListEnabled,
  },
  {
    name: 'notifyNoWorkStart',
    label: 'userList.operatorForm.form.noWorkStart',
    isRecommended: false,
    visible: true,
  },
  {
    name: 'notifyPCM',
    label: 'userList.operatorForm.form.pcm',
    isRecommended: false,
    visible: true,
  },
  {
    name: 'notifyBatteryDeepDischarge',
    label: 'userList.operatorForm.form.batteryDeepDischarge',
    isRecommended: true,
    visible: true,
  },
];

export const OperatorDetailsDrawer = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();

  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const isMachineReminderListEnabled = features.MACHINE_REMINDER_LIST;

  const [isOpenPop, setIsPopOpen] = useState(false);
  const [fields, setFields] = useState<Optional<NotificationOperator>>(undefined);
  const [page, setPage] = useState(1);

  const isRobotIntegrationEnabled = features.ROBOT_INTEGRATION;
  const hasAccessToRobots = useSelector(selectHasAccessToRobots);
  const showRobotCategories = isRobotIntegrationEnabled && hasAccessToRobots;

  const isVisible = useSelector(selectIsOperatorDetailsDrawerVisible);
  const isEditOperatorLoading = useSelector(UserListSelectors.selectOperatorFormIsLoading);
  const availableDevicesArray = useSelector(UserListSelectors.selectOperatorFormMachines);
  const isMachinesLoading = useSelector(UserListSelectors.selectOperatorFormMachinesAreLoading);
  const notificationCats = getNotificationCategories({ showRobotCategories, isMachineReminderListEnabled });
  const operator = useSelector(UserListSelectors.selectOperatorGetData);
  const [assignedDevices, setAssignedDevices] = useState<Optional<string>[]>([]);
  const isOperatorInformationLoading = !!useSelector(UserListSelectors.selectOperatorGetIsLoading);
  const isNotificationsLoading = !!useSelector(UserListSelectors.selectOperatorGetIsLoading);

  const defaultCountry = operator?.countryCode
    ? countries.find(country => country.code === operator?.countryCode)
    : countries.find(country => country.code === 'DE');
  const userPhoneNumber = operator?.phoneNumber ? operator.phoneNumber.replace(defaultCountry?.dialCode || '', '') : '';
  const [isSelectCountryCode, setIsSelectCountryCode] = useState<string>(defaultCountry?.code || '');
  const [isSelectCountryDialCode, setIsSelectCountryDialCode] = useState<string>(defaultCountry?.dialCode || '');

  const [isSelectNotiByEmail, setIsSelectNotiByEmail] = useState(false);
  const [isSelectNotiBySMS, setIsSelectNotiBySMS] = useState(false);
  const [isValidateNotiOptions, setIsValidateNotiOptions] = useState(false);

  const handleSelectNoti = (e: CheckboxChangeEvent): void => {
    const value = e.target.value;
    if (value === 'byEmail') {
      setIsSelectNotiByEmail(e.target.checked);
    } else {
      setIsSelectNotiBySMS(e.target.checked);
    }
    formInstance.validateFields(['notiOptions']);
  };

  const handleCountryChange = (value: string): void => {
    const dialCode = countries.find(country => country.code === value)?.dialCode;
    if (!dialCode) return;
    setIsSelectCountryDialCode(dialCode);
    setIsSelectCountryCode(value);
  };

  useEffect(() => {
    if (operator?.assignedMachines) {
      setAssignedDevices(operator.assignedMachines.filter(device => device?.id).map(device => device?.id));
    }

    setIsSelectCountryCode(defaultCountry?.code || '');
    setIsSelectCountryDialCode(defaultCountry?.dialCode || '');
    setIsSelectNotiByEmail(!!operator?.email);
    setIsSelectNotiBySMS(!!operator?.phoneNumber);
  }, [operator, defaultCountry]);

  const availableDevices = useMemo(() => {
    if (!availableDevicesArray) return [];
    return availableDevicesArray;
  }, [availableDevicesArray]);

  const sortedData = useMemo(() => {
    const initSelected = operator?.assignedMachines.map(machine => machine?.id) || [];
    const topDevices = initSelected.map(id => availableDevices.find(device => device.id === id)).filter(Boolean);
    const bottomDevices = availableDevices.filter(device => !initSelected.includes(device.id));
    return [...topDevices, ...bottomDevices];
  }, [availableDevices, operator?.assignedMachines]);

  const pagedData = useMemo(() => {
    const start = (page - 1) * 10;
    const end = start + 10;
    return sortedData.slice(start, end);
  }, [sortedData, page]);

  const onPageChange = (value: number): void => {
    setPage(value);
  };

  useEffect(() => {
    if (isVisible) {
      dispatch(
        UserListActions.getMachineListWithoutImageRequest({
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
        })
      );
    }
  }, [dispatch, isVisible]);

  const hideOperatorDrawer = (): void => {
    dispatch(DrawersActions.hideOperatorDetailsDrawer());
  };

  const onPopConfirm = (): void => {
    setIsPopOpen(false);
    dispatch(DrawersActions.hideOperatorDetailsDrawer());
    formInstance.resetFields();
  };
  const onPopCancel = (): void => setIsPopOpen(false);

  const handleHideModal = useCallback(() => {
    const isFieldsTouched = formInstance.isFieldsTouched();
    if (isFieldsTouched) {
      setIsPopOpen(true);
    } else {
      dispatch(DrawersActions.hideOperatorDetailsDrawer());
      formInstance.resetFields();
    }
  }, [dispatch, formInstance]);

  const handleSubmit = useCallback(() => {
    setFields(formInstance.getFieldsValue());
    formInstance.submit();
  }, [formInstance]);

  useAnalyticsSetForm({
    name: AnalyticsForm.UPSERT_OPERATOR,
    fields: fields as Record<string, any>,
    isVisible,
  });

  const onFinish = (values: OperatorFormValues): void => {
    if (assignedDevices.length === 0) {
      return;
    }

    let notifyReminder = values?.notifyReminder ?? false;
    const notifyEmergencyStop = values?.notifyEmergencyStop ?? false;
    const notifyResources = values?.notifyResources ?? false;
    const notifyErrorOccurred = values?.notifyErrorOccurred ?? false;
    const notifyAutonomousTaskStopped = values?.notifyAutonomousTaskStopped ?? false;
    const notifyCleaningTaskInterrupted = values?.notifyCleaningTaskInterrupted ?? false;
    const notifyCleaningTaskFinished = values?.notifyCleaningTaskFinished ?? false;
    const notifyLocationStatus = values?.notifyLocationStatus ?? false;
    const notifyNoWorkStart = values?.notifyNoWorkStart ?? false;
    const notifyOther = values?.notifyOther ?? false;
    const notifyInformation = values?.notifyInformation ?? false;
    const notifyServiceNeeded = values?.notifyServiceNeeded ?? false;
    const notifyPCM = values?.notifyPCM ?? false;
    const notifyBatteryDeepDischarge = values?.notifyBatteryDeepDischarge ?? false;

    let phoneInput = values.phoneNumber || '';
    if (phoneInput.startsWith('0')) {
      phoneInput = phoneInput.substring(1);
    }

    if (isMachineReminderListEnabled) {
      notifyReminder = values.notifyReminder || false;
    }

    analyticsLinkActivated({
      linkName: AnalyticsLink.OPERATOR_ASSIGNED_MACHINES,
    });

    const assignedMachines = availableDevices
      .filter(device => assignedDevices.includes(device.id))
      .map(device => ({
        id: device.id,
        name: device.name,
      }));

    const input = {
      id: operator?.id || undefined,
      name: values?.name?.trim() as string,
      phoneNumber: isSelectNotiBySMS ? isSelectCountryDialCode + phoneInput.replaceAll(' ', '') : undefined,
      countryCode: isSelectNotiBySMS ? isSelectCountryCode : undefined,
      email: isSelectNotiByEmail ? values?.email?.trim() : undefined,
      assignedMachines,
      language: values?.language || 'en',
      notifications: {
        notifyAutonomousTaskStopped,
        notifyCleaningTaskFinished,
        notifyCleaningTaskInterrupted,
        notifyEmergencyStop,
        notifyErrorOccurred,
        notifyInformation,
        notifyLocationStatus,
        notifyNoWorkStart,
        notifyOther,
        notifyPCM,
        notifyReminder,
        notifyResources,
        notifyServiceNeeded,
        notifyBatteryDeepDischarge,
      },
    };

    dispatch(UserListActions.saveOperatorRequest({ input }));
  };

  const handleToggleSelectDevice = useCallback(
    (machineId: string) => {
      if (assignedDevices.includes(machineId)) {
        setAssignedDevices(assignedDevices.filter(id => id && id !== machineId));
      } else {
        setAssignedDevices([...assignedDevices, machineId]);
      }
    },
    [assignedDevices]
  );

  const togglePage = (): void => {
    const pagedIds = pagedData.map(machine => machine?.id || '');
    const isSelected = pagedIds.every(id => assignedDevices.includes(id));
    if (isSelected) {
      setAssignedDevices(assignedDevices.filter(id => id && !pagedIds.includes(id)));
    } else {
      setAssignedDevices([...assignedDevices, ...pagedIds]);
    }
  };

  const isAllDataInPageSelected = pagedData.every(machine => assignedDevices.includes(machine?.id));

  const tableColumns = getOperatorSelectedDevicesColumns({ t, assignedDevices, isAllDataInPageSelected, togglePage });

  const handleOpenDeleteOperatorModal = ({ id }: NotificationOperator): void => {
    dispatch(UserModalsActions.showDeleteOperatorModal({ id: id as string }));
  };

  useEffect(() => {
    if (operator) {
      formInstance.setFieldsValue({
        id: operator.id,
        name: operator?.name || '',
        email: operator?.email || '',
        phoneNumber: userPhoneNumber,
        countryCode: operator?.countryCode || defaultCountry?.code,
        language: operator?.language || languageOptions[0].value,
        notifyLocationStatus: operator?.notifyLocationStatus ?? false,
        notifyResources: operator?.notifyResources ?? false,
        notifyAutonomousTaskStopped: operator?.notifyAutonomousTaskStopped ?? false,
        notifyCleaningTaskInterrupted: operator?.notifyCleaningTaskInterrupted ?? false,
        notifyCleaningTaskFinished: operator?.notifyCleaningTaskFinished ?? false,
        notifyEmergencyStop: operator?.notifyEmergencyStop ?? false,
        notifyErrorOccurred: operator?.notifyErrorOccurred ?? false,
        notifyReminder: operator?.notifyReminder ?? false,
        notifyNoWorkStart: operator?.notifyNoWorkStart ?? false,
        notifyPCM: operator?.notifyPCM ?? false,
        notifyOther: operator?.notifyOther ?? false,
        notifyInformation: operator?.notifyInformation ?? false,
        notifyServiceNeeded: operator?.notifyServiceNeeded ?? false,
        assignedMachines: operator?.assignedMachines?.map(machine => machine?.id) || [],
        notifyBatteryDeepDischarge: operator?.notifyBatteryDeepDischarge ?? false,
      });
      setPage(1);
    }
  }, [operator, formInstance, userPhoneNumber, defaultCountry]);

  useEffect(() => {
    formInstance.validateFields(['notiOptions']);
  }, [isSelectNotiByEmail, isSelectNotiBySMS, formInstance]);

  return (
    <StyledOperatorDetailsDrawer
      className="operator-details-drawer"
      title={isOperatorInformationLoading ? 'N/A' : operator?.name}
      width={680}
      mask={false}
      open={isVisible}
      onClose={hideOperatorDrawer}
      footer={[
        <Col className="operator-details-drawer__changes-button-col" key="changes-button">
          <PopConfirm
            key="cancel-button"
            title={t('common.popConfirm.title')}
            description={t('common.popConfirm.description')}
            cancelText={t('common.popConfirm.cancelText')}
            okText={t('common.popConfirm.okText')}
            open={isOpenPop}
            onConfirm={onPopConfirm}
            onCancel={onPopCancel}
          >
            <SecondaryButton
              size="m"
              key="cancel-button"
              className="operator-details-drawer__secondary-button"
              onClick={handleHideModal}
            >
              {t('common.discardChanges')}
            </SecondaryButton>
          </PopConfirm>
          <PrimaryButton
            size="m"
            key="submit"
            className="operator-details-drawer__submit-button"
            loading={isEditOperatorLoading}
            onClick={handleSubmit}
          >
            {t('common.saveChanges')}
          </PrimaryButton>
        </Col>,
      ]}
    >
      <Form
        form={formInstance}
        name="operator-details-drawer"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        initialValues={{
          id: operator?.id,
          name: operator?.name || '',
          email: operator?.email,
          phoneNumber: operator?.phoneNumber,
          countryCode: defaultCountry?.code,
          language: operator?.language || languageOptions[0].value,
          notifyLocationStatus: operator?.notifyLocationStatus ?? false,
          notifyResources: operator?.notifyResources ?? false,
          notifyAutonomousTaskStopped: operator?.notifyAutonomousTaskStopped ?? false,
          notifyCleaningTaskInterrupted: operator?.notifyCleaningTaskInterrupted ?? false,
          notifyCleaningTaskFinished: operator?.notifyCleaningTaskFinished ?? false,
          notifyEmergencyStop: operator?.notifyEmergencyStop ?? false,
          notifyErrorOccurred: operator?.notifyErrorOccurred ?? false,
          notifyReminder: operator?.notifyReminder ?? false,
          notifyNoWorkStart: operator?.notifyNoWorkStart ?? false,
          notifyPCM: operator?.notifyPCM ?? false,
          notifyOther: operator?.notifyOther ?? false,
          notifyInformation: operator?.notifyInformation ?? false,
          notifyServiceNeeded: operator?.notifyServiceNeeded ?? false,
          assignedMachines: operator?.assignedMachines?.map(machine => machine?.id) || [],
        }}
      >
        <Flex vertical>
          {/* Operator information */}
          <Card
            className="operator-details-drawer__card operator-details-drawer__information"
            title={<h3>{t('operatorDetailsPanel.operatorInformation')}</h3>}
          >
            {isOperatorInformationLoading ? (
              <Spin size="small" />
            ) : (
              <>
                <Form.Item
                  name="name"
                  label={t('userList.operatorForm.form.name')}
                  className="operator-details-drawer__input-group"
                  required
                  rules={[{ required: true, message: t('userList.operatorForm.form.errors.nameRequired') }]}
                >
                  <Input className="operator-details-drawer__input" />
                </Form.Item>

                <Form.Item
                  name="language"
                  label={t('userList.operatorForm.form.language')}
                  className="operator-details-drawer__input-group operator-details-drawer__select"
                >
                  <Select
                    dropdownVisibleState
                    className="operator-details-drawer__select"
                    placeholder={t('userList.operatorForm.form.placeholderLanguage')}
                    options={languageOptions.map(({ value, content }) => ({
                      label: t(content),
                      value,
                    }))}
                  />
                </Form.Item>

                {isSelectNotiByEmail && (
                  <Form.Item
                    name="email"
                    label={t('userList.operatorForm.form.email')}
                    className="operator-details-drawer__input-group"
                    required
                    rules={[
                      { required: true, message: t('userList.operatorForm.form.errors.emailRequired') },
                      {
                        type: 'email',
                        message: t('userList.operatorForm.form.errors.emailInvalid'),
                      },
                    ]}
                  >
                    <Input className="operator-details-drawer__input" />
                  </Form.Item>
                )}

                {isSelectNotiBySMS && (
                  <Form.Item
                    label={t('userList.operatorForm.form.phoneNumber')}
                    className="operator-details-drawer__input-group"
                  >
                    <Row>
                      <Form.Item
                        name="countryCode"
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: t('userList.operatorForm.form.errors.countryCodeRequired'),
                          },
                        ]}
                      >
                        <Select
                          dropdownVisibleState
                          selectedOptionsOnTop
                          className="operator-details-drawer__input__select-phone-code"
                          options={countries.map(country => ({
                            label: `${country.flag} ${country.code} (${country.dialCode})`,
                            value: country.code,
                          }))}
                          onChange={handleCountryChange}
                          defaultValue={defaultCountry?.code}
                        />
                      </Form.Item>
                      <Form.Item
                        name="phoneNumber"
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: t('userList.operatorForm.form.errors.phoneNumberRequired'),
                          },
                          {
                            pattern: /^\d{9,12}$/,
                            message: t('userList.operatorForm.form.phoneNumberInvalid'),
                          },
                        ]}
                      >
                        <Input className="operator-details-drawer__input-phone-code" />
                      </Form.Item>
                    </Row>
                  </Form.Item>
                )}

                <Form.Item
                  name="notiOptions"
                  label={t('userList.operatorForm.form.notificationOptions.title')}
                  className="operator-form__checkbox"
                  rules={[
                    {
                      validator: (): Promise<void> => {
                        if (!isSelectNotiByEmail && !isSelectNotiBySMS) {
                          setIsValidateNotiOptions(true);
                          return Promise.reject(
                            new Error(t('userList.operatorForm.form.errors.notificationOptionsRequired'))
                          );
                        }
                        setIsValidateNotiOptions(false);
                        return Promise.resolve();
                      },
                    },
                  ]}
                  required
                  validateTrigger="onChange"
                >
                  <Col span={24}>
                    <Checkbox
                      className={`operator-form__checkbox-label ${isValidateNotiOptions ? 'has-error' : ''}`}
                      onChange={handleSelectNoti}
                      value="byEmail"
                      checked={isSelectNotiByEmail}
                    >
                      {t('userList.operatorForm.form.notificationOptions.byEmail')}
                    </Checkbox>
                    <Checkbox
                      className={`operator-form__checkbox-label ${isValidateNotiOptions ? 'has-error' : ''}`}
                      onChange={handleSelectNoti}
                      value="bySMS"
                      checked={isSelectNotiBySMS}
                    >
                      {t('userList.operatorForm.form.notificationOptions.bySMS')}
                    </Checkbox>
                  </Col>
                </Form.Item>
              </>
            )}
          </Card>
          {/* Selected devices */}
          <Card
            className="operator-details-drawer__card operator-details-drawer__selected-devices"
            title={<h3>{t('operatorDetailsPanel.selectedDevices.title')}</h3>}
          >
            <Table
              dataSource={pagedData}
              loading={isMachinesLoading}
              className="operator-details-drawer__device-list__table"
              columns={tableColumns}
              rowKey="machineId"
              onRow={({ id }): { onClick(): void } => ({
                onClick: (): void => id && handleToggleSelectDevice(id),
              })}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t('userList.operatorForm.form.tableNoDevicesFound')}
                  />
                ),
              }}
              pagination={
                availableDevices.length > 10
                  ? {
                      total: availableDevices.length,
                      pageSize: 10,
                      page,
                      onPageChange,
                    }
                  : undefined
              }
              scroll={{ y: undefined }}
            />
            {!isMachinesLoading && assignedDevices.length === 0 && (
              <div className="operator-details-drawer__device-list__error">
                {t('operatorDetailsPanel.selectedDevices.table.devicesRequired')}
              </div>
            )}
          </Card>
          {/* Notification categories */}
          <Card
            className="operator-details-drawer__card operator-details-drawer__selected-notifications"
            title={<h3>{t('operatorDetailsPanel.notificationCategories.title')}</h3>}
          >
            <Col span={24} className="operator-details-drawer__selected-notifications--sub-title-wrapper">
              <div className="operator-details-drawer__selected-notifications--sub-title">
                {t('operatorDetailsPanel.notificationCategories.subTitle')}
              </div>
              {showRobotCategories && (
                <div className="operator-details-drawer__selected-notifications--recommended">
                  &#42; {t('userList.operatorForm.recommended')}
                </div>
              )}
            </Col>
            {isNotificationsLoading ? (
              <Spin size="small" />
            ) : (
              <Row className="operator-details-drawer__selected-notifications--checkbox-row">
                {notificationCats.map(item => {
                  const { label, name, isRecommended, visible } = item;
                  if (!visible) return <></>;
                  return (
                    <Col key={`${name}`} span={12}>
                      <Form.Item name={name} valuePropName="checked">
                        <Checkbox className="operator-details-drawer__selected-notifications--checkbox">
                          <div className="operator-details-drawer__selected-notifications--checkbox-label">
                            {t(label)} {isRecommended && <span>&#42;</span>}
                          </div>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Card>

          <div className="operator-details-drawer__delete-button-wrapper" key="delete-button">
            <PermissionGuard requiredPermissions={[Permission.Customer.User.DELETE_OPERATOR]} key="delete-button">
              <SecondaryButton
                size="m"
                title={t('operatorDetailsPanel.deleteBtn')}
                key="delete-button"
                className="operator-details-drawer__secondary-button"
                onClick={(): void => handleOpenDeleteOperatorModal(operator as NotificationOperator)}
              >
                {t('operatorDetailsPanel.deleteBtn')}
              </SecondaryButton>
            </PermissionGuard>
          </div>
        </Flex>
      </Form>
      <DeleteOperatorModal />
    </StyledOperatorDetailsDrawer>
  );
};
